import React from "react";
// node.js library that concatenates classes (strings)
import classnames from "classnames";

import axios from 'axios';

import '../assets/css/datatable.css';

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  NavItem,
  NavLink,
  Nav,
  Progress,
  Table,
  Container,
  Row,
  Col
} from "reactstrap";

import Header from "components/Headers/Header.js";
import Moment from "moment";
import 'moment/locale/el';

import { MDBDataTable } from 'mdbreact';

class Index extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      user_token: '',
      activeNav: 1,
      isLoaded: false,
      instances: [],
      applications: [],
      user: [],
      dummy: [],
      dummy2: []
    };
  };

  handleClick = instanceId => {
    if (window.confirm("Είστε σίγουροι ότι θέλετε να διαγράψετε την συγκεκριμένη επιθεώρηση;") == true) {
      if (localStorage.getItem('token')) {
        axios.delete(process.env.REACT_APP_API_LINK + '/api/workflowinstances/' + instanceId + "/", {
          headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
        })
          .then(res => {
            window.location.reload(false);
          })
      }
    } else {

    }
  }

  handleSeen = instanceId => {
    if (localStorage.getItem('token')) {
      fetch(process.env.REACT_APP_API_LINK + '/api/workflowinstances/changeseen/' + instanceId + "/", {
        method: "POST",
        headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
      })
        .then(res => res.json())
        .then(res => {

        })
    }

    // if (localStorage.getItem('token')) {
    //   axios.post(process.env.REACT_APP_API_LINK + '/api/workflowinstances/changeseen/' + instanceId + "/", {
    //     headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
    //   })
    //     .then(res => {
    //       // window.location.reload(false);
    //     })
    // }
  }

  async componentDidMount() {
    if (localStorage.getItem('token')) {

      await fetch(process.env.REACT_APP_API_LINK + "/api/auth/user/", {
        method: 'get',
        headers: new Headers({
          'Authorization': 'Bearer ' + localStorage.getItem('token'),
          'Content-Type': 'application/x-www-form-urlencoded'
        })
      })
        .then(res => res.json())
        .then(
          (result) => {
            // console.log(result);
            this.setState({
              isLoaded: true,
              user: result,
            });
          },
          (error) => {
            // console.log(error);
            this.setState({
              isLoaded: true,
              error
            });
          }
        )

      await fetch(process.env.REACT_APP_API_LINK + "/api/workflowinstances/all/all/", {
        method: "GET",
        headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
      })
        .then(res => res.json())
        .then(res => {
          // console.log(res)
          const instances = res;
          this.setState({ instances });

          let self = this
          let role = this.state.user
          console.log(role.PersonRole);
          res.forEach(function (part, index) {


            if (role.PersonRole == 'Admin') {
              this[index].DeleteButtons =
                <React.Fragment>
                  <Button
                    color="danger"
                    onClick={() => { self.handleClick(part[0].Id) }}
                    size="sm"
                    title="Διαγραφή"
                  >
                    <i className="fas fa-trash" />
                  </Button>
                </React.Fragment>
            }

            if (part[0].Seen && part[0].Seen.includes(self.state.user.Email)) {
              if (part[0].Locked) {
                this[index].Buttons =
                  <React.Fragment>
                    <Button
                      disabled="true"
                      color="primary"
                      onClick={() => { self.handleSeen(part[0].Id) }}
                      href={"/admin/instance/" + part[0].Id}
                      size="sm"
                    >
                      <i className="fas fa-folder-open" />
                    </Button>
                    <Button
                      disabled="true"
                      color="secondary"
                      size="sm"
                    >
                      <i className="fas fa-eye" />
                    </Button>
                  </React.Fragment>
              }
              else {
                this[index].Buttons =
                  <React.Fragment>
                    <Button
                      color="primary"
                      onClick={() => { self.handleSeen(part[0].Id) }}
                      href={"/admin/instance/" + part[0].Id}
                      size="sm"
                    >
                      <i className="fas fa-folder-open" />
                    </Button>
                    <Button
                      disabled="true"
                      color="secondary"
                      size="sm"
                    >
                      <i className="fas fa-eye" />
                    </Button>
                  </React.Fragment>
              }
            }
            else {
              //// console.log(part[0].Id);
              if (part[0].Locked) {
                this[index].Buttons =
                  <React.Fragment>
                    <Button
                      disabled="true"
                      color="primary"
                      onClick={() => { self.handleSeen(part[0].Id) }}
                      href={"/admin/instance/" + part[0].Id}
                      size="sm"
                    >
                      <i className="fas fa-folder-open" />
                    </Button>

                    {/* {this.state.user.PersonRole == 'Admin' ?
                  <>
                    <Button
                      color="danger"
                      // onClick={() => { this.handleClick(dynamicData.Id) }}
                      size="sm"
                      title="Διαγραφή"
                    >
                      <i className="fas fa-trash" />
                    </Button>
                  </> : <> </>} */}
                  </React.Fragment>
              }
              else {
                this[index].Buttons =
                  <React.Fragment>
                    <Button
                      color="primary"
                      onClick={() => { self.handleSeen(part[0].Id) }}
                      href={"/admin/instance/" + part[0].Id}
                      size="sm"
                    >
                      <i className="fas fa-folder-open" />
                    </Button>

                    {/* {this.state.user.PersonRole == 'Admin' ?
                  <>
                    <Button
                      color="danger"
                      // onClick={() => { this.handleClick(dynamicData.Id) }}
                      size="sm"
                      title="Διαγραφή"
                    >
                      <i className="fas fa-trash" />
                    </Button>
                  </> : <> </>} */}
                  </React.Fragment>
              }
            }


            this[index].NewStatus = 
            part[0].Status === 1
                ? "1. Αναγγελία"
                : part[0].Status === 2
                ? "2. Απαιτείται ο προγραμματισμός της επιθεώρησης"
                : part[0].Status === 3
                ? "3. Απαιτείται συμπλήρωση στοιχείων επιθεώρησης"
                : part[0].Status === 4
                ? "4. Απαιτείται ενέργεια από τον ΔΕΔΔΗΕ για ολοκλήρωση"
                : "";
        
        this[index].Program = "Επιθεωρήσεις Ξύλινων Υλικών";
        
        let ress = part[1];
        // Add braces only if `ress` is not already a valid JSON object
        if (!ress.trim().startsWith("{") || !ress.trim().endsWith("}")) {
            ress = "{" + ress + "}";
        }
        
        // Remove unwanted characters (newlines, tabs, etc.)
        ress = ress.replace(/[\n\r\t]/g, '');
        
        // Attempt to parse JSON
        let obj;
        try {
            obj = JSON.parse(ress);
        } catch (error) {
            console.error("Error parsing JSON:", error.message);
            console.error("Invalid JSON string:", ress);
            obj = {}; // Default to an empty object or handle appropriately
        }
        
        // Assign parsed values if the JSON is valid
        this[index].SerialAnaggelias = obj["Αριθμός"] || "";
        this[index].DateAnaggelias = obj["Ημερομηνία"] || "";
        this[index].Promitheutis = obj["Προμηθευτής"] || "";
        this[index].SerialEkthesis = obj["Αριθμός έκθεσης"] || "";
        this[index].SerialNumber = part[0].SerialNumber || "";
        this[index].EditedBy = part[0].EditedBy || "";
        this[index].PreviousSerialNumber = part[0].PreviousSerialNumber || "";
        
        // Format the `SubmittedAt` date
        const temp_date = new Date(part[0].SubmittedAt);
        this[index].SubmittedAt = temp_date.toLocaleString('el-GR');
        

          }, res);

          this.state.dummy = {
            columns: [
              {
                label: 'Α/Α ▼',
                field: 'SerialNumber',
                sort: 'asc',
                width: 100
              },
              {
                label: 'Μεταβαση',
                field: 'Buttons',
                sort: 'asc',
                width: 150
              },
              {
                label: 'Αριθμος Αναγγελιας ▼',
                field: 'SerialAnaggelias',
                sort: 'asc',
                width: 100
              },
              {
                label: 'Ημερομηνια Αναγγελιας ▼',
                field: 'DateAnaggelias',
                sort: 'asc',
                width: 100
              },
              {
                label: 'Προμηθευτης ▼',
                field: 'Promitheutis',
                sort: 'asc',
                width: 100
              },
              {
                label: 'Κατασταση ▼',
                field: 'NewStatus',
                sort: 'asc',
                width: 100
              },
              {
                label: 'Τροποποιηθηκε Απο ▼',
                field: 'EditedBy',
                sort: 'asc',
                width: 150
              },
              {
                label: 'Διαγραφή',
                field: 'DeleteButtons',
                sort: 'asc',
                width: 150
              },
              {
                label: 'Ημ/νία Δημιουργίας ▼',
                field: 'SubmittedAt',
                sort: 'asc',
                width: 150
              },
              {
                label: 'Ορθή Επανάληψη ▼',
                field: 'PreviousSerialNumber',
                sort: 'asc',
                width: 100
              },
              {
                label: 'Αριθμος εκθεσης ▼',
                field: 'SerialEkthesis',
                sort: 'asc',
                width: 100
              },
              // {
              //   label: 'Email ▼',
              //   field: 'Email',
              //   sort: 'asc',
              //   width: 150
              // },

            ],
            rows: this.state.instances,
          };

          this.forceUpdate()
          // console.log(instances);
        })


    };

  }

  render() {
    return (
      <>
        <Header />
        {/* Page content */}
        <Container className="mt--7" fluid>
          <Row className="mt-3">
            <Col className="mb-5 mb-xl-0" xl="12">
              <Card className="shadow">
                <CardHeader className="border-0">
                  <Row className="align-items-center">
                    <div className="col">
                      <h3 className="mb-0">Ενεργές Επιθεωρήσεις</h3>
                    </div>
                    <div className="col text-right">
                      {this.state.user.PersonRole == 'Admin' || this.state.user.PersonRole == 'Deddie' ?
                        <>
                          <Button
                            color="success"
                            href={"/admin/newinstance"}
                            size="sm"
                          >
                            Δημιουργία νέας

                          </Button>
                        </> : <> </>}
                      <Button
                        color="primary"
                        href={"/admin/instances"}
                        size="sm"
                      >
                        Προβολή όλων

                      </Button>
                    </div>
                  </Row>
                </CardHeader>
                {/* <Table className="align-items-center table-flush" responsive>
                  <thead className="thead-light">
                    <tr>
                      <th scope="col">Α/Α</th>
                      <th scope="col">Προγραμμα</th>
                      <th scope="col">Κατασταση</th>
                      <th scope="col">Τροποποιηθηκε απο</th>
                      <th scope="col">Μεταβαση</th>
                    </tr>
                  </thead>
                  <tbody>
                    {
                      this.state.instances.map((dynamicData) =>
                        <tr>
                          <th scope="row"> {dynamicData.SerialNumber}/{new Date().getFullYear()}</th>
                          {/* <td> {dynamicData.ProtocolNumber} </td> */}
                {/* <td>Επιθεωρήσεις Ξύλινων Υλικών</td>
                          {dynamicData.Status == 1 ? <td>1. Αναγγελία</td> : <> </>}
                          {dynamicData.Status == 2 ? <td>2. Προγραμματισμός Επιθεώρησης</td> : <> </>}
                          {dynamicData.Status == 3 ? <td>3. Στοιχεία Επιθεώρησης</td> : <> </>}
                          {dynamicData.Status == 4 ? <td>4. ΔΕΔΔΗΕ Ολοκλήρωση</td> : <> </>}
                          <td>{dynamicData.EditedBy}</td>
                          <td>
                            <Button
                              color="primary"
                              href={"/admin/instance/" + dynamicData.Id}
                              size="sm"
                            >
                              Βήματα
                            </Button>
                          </td>
                        </tr>
                      )
                    }
                  </tbody>
                </Table> */}

                <CardHeader className="border-0">
                  <Row className="align-items-center">
                    <div className="col">
                      <MDBDataTable
                        responsive
                        bordered
                        exportToCSV
                        hover
                        strip
                        data={this.state.dummy}
                        id="mdbdttable"
                        entriesOptions={[10, 25, 50, 100, 500, 1000]} entries={10}
                        noDataComponent="Δεν υπάρχουν δεδομένα"
                        searchLabel="Αναζήτηση"
                        paginationLabel={['Προηγούμενο', 'Επόμενο']}
                        infoLabel={['Εμφανίζονται', 'έως', 'από', 'καταχωρήσεις']}
                        entriesLabel={'Εμφάνιση Αιτημάτων ανά: '}
                      />
                    </div>

                  </Row>
                </CardHeader>

              </Card>
            </Col>
          </Row>

          {/* <Row className="mt-5">
            <Col className="mb-5 mb-xl-0" xl="12">
              <Card className="shadow">
                <CardHeader className="border-0">
                  <Row className="align-items-center">
                    <div className="col">
                      <h3 className="mb-0">Μη υποβεβλημένες αιτήσεις</h3>
                    </div>
                    <div className="col text-right">
                      {this.state.user.PersonRole == 'Admin' || this.state.user.PersonRole == 'Sales' ? 
                        <>
                          <Button
                            color="success"
                            href={"https://deda.gr/connect"}
                            size="sm"
                          >
                            Δημιουργία νέας
                            
                          </Button>
                        </> : <> </>}
                      <Button
                        color="primary"
                        href={"/admin/applications"}
                        size="sm"
                      >
                        Προβολή όλων
                        
                      </Button>
                    </div>
                  </Row>
                </CardHeader>
                <Table className="align-items-center table-flush" responsive>
                  <thead className="thead-light">
                    <tr>
                      <th scope="col">Ονομα</th>
                      <th scope="col">Επιθετο</th>
                      <th scope="col">Τηλεφωνο Επικοινωνιας</th>
                      <th scope="col">Ημερομηνια Υποβολης</th>
                      <th scope="col">Ενεργειες</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    {
                      this.state.applications.map((dynamicData) =>
                        <tr> 
                          <th scope="row"> {dynamicData.Name}</th> 
                        <td>{dynamicData.LastName}</td>
                        <td>{dynamicData.phoneNumber}</td>
                        <td>{Moment(dynamicData.dateCreated).locale('el').format('dddd, DD MMM YYYY')}</td>
                        <td>
                          <Button
                            color="primary"
                            href={"/admin/application/" + dynamicData.Id}
                            size="sm"
                          >
                            Προβολή
                          </Button></td>
                        </tr>
                      ) 
                    }
                  </tbody>
                </Table>
              </Card>
            </Col>
          </Row> */}
          <div class='push'></div>
        </Container>
      </>
    );
  }
}

export default Index;
